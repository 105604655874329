
import Cookies from 'js-cookie'
/**
 * Functionality to pass on utm params to 1life from cookies
 */
export default class SetUtmParams {
    /**
     * @desc Read UTM cookies, update links to 1life to include the utm values as query params
     *
     */

    constructor() {
        let utmTerms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

        let links = this.getOnelifeLinks()
        let query = this.constructQuery(utmTerms)

        if (query.length > 0) {
            this.appendQueryToLinks(links, query)
        }

        // Feature flag for 'ref_' param
        window.LDClient.waitForInitialization().then(() => {
            if (window.LDClient.variation('ref-param')) {
                this.appendRefToAmazonLinks(this.getAmazonLinks(), query)
            }
        }).catch(err => {
            console.error('error during LDClient initialization: ', err)
        })
    }

    getOnelifeLinks() {
        let links = []
        document.querySelectorAll('a').forEach((a) => {
            if (a.href.match(/members.app.onemedical/)) {
                links.push(a)
            } else if (a.href.match(/app.onemedical/)) {
                links.push(a)
            }
        })
        return links
    }
    getAmazonLinks() {
        let amazonLinks = []
        document.querySelectorAll('a').forEach((a) => {
            if (a.href.match(/amazon.com/)) {
                amazonLinks.push(a)
            }
        })
        return amazonLinks
    }

    constructQuery(utmTerms) {
        let query = '', index = 0
        let searchParams = new URL(window.location.href).searchParams

        utmTerms.forEach((term) => {
            // first, check for UTM value in URL parameter
            let utmValue = searchParams.get(term) ?? false

            // second, check for UTM value in cookie
            if (!utmValue) {
                // check cookie exists and value is not set to string "undefined"
                utmValue = Cookies.get(term) ?? false
                utmValue = (utmValue && utmValue.length) > 0 ? utmValue : false
            }
            utmValue = utmValue === 'undefined' ? false : utmValue

            if (utmValue) {
                let queryValue = utmValue.match(/\?/) ? utmValue.split('?')[0] : utmValue
                query += (index++ > 0) ? '&' : '?' // add delimiter
                query += term + '=' + queryValue
            }
        })
        return query.substring(1) // strip the leading '?' if it exists
    }

    appendQueryToLinks(links, query) {
        links.forEach((link) => {
            let glue = link.href.match(/\?/) ? '&' : '?'
            link.href += glue + query
        })
    }

    appendRefToAmazonLinks(amazonLinks, utmParams) {

        // Get the 'ref_' param from the page URL (if it exists)
        const pageUrlRef = new URLSearchParams(window.location.search).get('ref_')

        amazonLinks.forEach((link) => {

            // CTA link and params
            const ctaLink = new URL(link.href)
            const ctaLinkParams = ctaLink.searchParams
            const ctaName = ctaLinkParams.get('cta') ?? ''

            // Remove the 'cta' param from the CTA link (its value will be added to the 'ref_' so it is not needed as a separate param)
            ctaLinkParams.delete('cta')

            // Now we will construct the value for the CTA link 'ref_' param
            let ctaRefValue

            // If the 'ref_' param exists in the page URL...
            if (pageUrlRef) {

                // Set the CTA link 'ref_' value to the page URL 'ref_' value (and include the CTA name)
                ctaRefValue = `${pageUrlRef}_${ctaName}`

            } else {

                // If UTM params exist in the page URL (and no 'ref_')...
                if (utmParams) {

                    // Format the UTM params so the values are separated by underscores
                    const formattedUtmParams = utmParams.replace(/&.*?=/g, '_').replace(/^(.+?)=/, '')

                    // Set the CTA 'ref_' value to the formatted UTM values (and include the CTA name)
                    ctaRefValue = `${formattedUtmParams}_${ctaName}`

                } else {

                    // If no 'ref_' and no UTM params exist in the page URL, just use the CTA name as the 'ref_' value for the CTA link
                    ctaRefValue = ctaName

                }
            }
            ctaLinkParams.set('ref_', ctaRefValue)
            link.href = ctaLink
        })
    }
}
